// extracted by mini-css-extract-plugin
export var app = "layout-module--app--8dcc6";
export var appBody = "layout-module--app-body--f2065";
export var brandPeriod = "layout-module--brandPeriod--1bde3";
export var dataLoading = "layout-module--data-loading--8241c";
export var dataLoadingError = "layout-module--data-loading-error--d81ff";
export var heading = "layout-module--heading--ddaca";
export var navLinkItem = "layout-module--nav-link-item--6b1dd";
export var navLinkText = "layout-module--nav-link-text--7a610";
export var navLinks = "layout-module--nav-links--109b3";
export var siteTitle = "layout-module--site-title--5b122";