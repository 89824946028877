import React from "react";
import {createTheme} from "@mui/material/styles";
import {orange, purple} from "@mui/material/colors";

declare module '@mui/material/styles' {
    interface TypographyVariants {
        iconLabel: React.CSSProperties;
        sessionNote: React.CSSProperties;
        sessionTimeLarge: React.CSSProperties;
        sessionTimeLabel: React.CSSProperties;
        sessionPrice: React.CSSProperties;
    }

    // allow configuration using `createTheme`
    interface TypographyVariantsOptions {
        iconLabel?: React.CSSProperties;
        sessionNote?: React.CSSProperties;
        sessionTimeLarge?: React.CSSProperties;
        sessionTimeLabel?: React.CSSProperties;
        sessionPrice?: React.CSSProperties;
    }
}

// Update the Typography's variant prop options
declare module '@mui/material/Typography' {
    interface TypographyPropsVariantOverrides {
        iconLabel: true;
        sessionNote: true;
        sessionTimeLarge: true;
        sessionTimeLabel: true;
        sessionPrice: true;
    }
}

const customTheme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#FF7600',
        },
        secondary: {
            main: '#f44336',
        },
        background: {
            default: '#242424'
        }
    },
    typography: {
        fontSize: 16,
        fontFamily: [
            '"Open Sans"',
            '"Helvetica Neue"',
            'Arial',
            'sans-serif'
        ].join(',')
    }
});

customTheme.typography.iconLabel = {
    fontFamily: [
        '"Lato"'
    ].join(','),
    lineHeight: '.813rem',
    fontWeight: '700',
    fontSize: '.75rem'
};

customTheme.typography.body1 = {
    fontFamily: [
        '"Open Sans"'
    ].join(','),
    lineHeight: '1.375rem',
    fontWeight: '400',
    backgroundColor: '#242424'
};

customTheme.typography.h1 = {
    fontFamily: [
        'Lato'
    ].join(','),
    fontSize: '2.5rem',
    fontWeight: 900,
    lineHeight: '2.25rem',
    '@media (min-width:600px)': {
        fontSize: '3.125rem',
        lineHeight: '2.675rem'
    },
    [createTheme().breakpoints.up('md')]: {
        fontSize: '3.5rem',
    },
};

customTheme.typography.h2 = {
    fontFamily: [
        'Lato'
    ].join(','),
    fontSize: '1.875rem',
    fontWeight: 900,
    lineHeight: '2.675rem',
    textTransform: 'uppercase',
    textAlign: 'center',
    '@media (min-width:600px)': {
        fontSize: '2.188rem',
    },
    [createTheme().breakpoints.up('md')]: {
        fontSize: '2.5rem',
    },
};

customTheme.typography.h3 = {
    fontFamily: 'Lato',
    fontSize: '1.125rem',
    fontWeight: 900,
    textTransform: 'uppercase',
    textAlign: 'center',
    // '@media (min-width:600px)': {
    //     fontSize: '2.188rem',
    // },
    // [createTheme().breakpoints.up('md')]: {
    //     fontSize: '2.5rem',
    // },
};

customTheme.typography.sessionNote = {
    fontSize: '0.625rem',
    color: '#595959',
    textAlign: 'center',
    //@ts-ignore
    '@media (min-width:600px)': {
        fontSize: '.875rem',
        letterSpacing: 'initial',
    }
};

customTheme.typography.sessionTimeLarge = {
    fontFamily: 'Lato',
    fontSize: '3.125rem',
    color: '#F06E00',
    textAlign: 'center',
    letterSpacing: '-.1rem',
    lineHeight: '3.625rem',
    display: 'grid',
    placeItems: 'center',
    fontWeight: 700,
    'WebkitTextStrokeWidth': '1px',
    'WebkitTextStrokeColor': 'rgba(143, 68, 0,1)',
    //@ts-ignore
    '@media (max-width:459px)': {
        lineHeight: '2.188rem'
    },
    '@media (min-width:600px)': {
        fontSize: '4rem',
        lineHeight: '3.125rem',
    },
    [createTheme().breakpoints.up('md')]: {
        fontSize: '5.625rem',
        lineHeight: '4.375rem',
    },
};
customTheme.typography.sessionTimeLabel = {
    fontFamily: 'Lato',
    fontSize: '.875rem',
    fontWeight: 700,
    color: '#F06E00',
    'WebkitTextStrokeWidth': '.25px',
    'WebkitTextStrokeColor': 'rgba(143, 68, 0,1)',
    //@ts-ignore
    '@media (min-width:768px)': {
        fontSize: '1rem'
    },
    [createTheme().breakpoints.up('md')]: {
        fontSize: '1rem'
    },
};


customTheme.typography.sessionPrice = {
    fontFamily: 'Lato',
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    placeItems: 'baseline',
    fontSize: '1rem',
    color: 'white',
    backgroundColor: '#4C4C4C',
    textAlign: 'center',
    padding: '2px 5px',
    borderRadius: '5px',
    fontWeight: 400,
    gridColumnGap: '2px',
    //@ts-ignore
    'span': {
        fontStyle: 'italic',
        fontSize: '1rem',
        color: '#C4C4C4',
        '@media (min-width:768px)': {
            fontSize: '1.125rem'
        }
    },
    '@media (max-width:459px)': {
        placeContent: 'center',
        gridTemplateColumns: 'auto auto',
    },
    '@media (min-width:768px)': {
        fontSize: '1.875rem',
        padding: '7px 8px 5px',
        gridTemplateColumns: 'auto auto',
    }
};

export default customTheme;