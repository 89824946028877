import React, { useContext, useEffect, useState } from "react";
// import {AppCtx} from "../../contexts/GlobalContext";
import { motion, Variants } from 'framer-motion';
import { header, headerLeft, headerRight, navActive } from "./header.module.scss"
import { graphql, Link, useStaticQuery } from 'gatsby'
import { StaticImage } from "gatsby-plugin-image";
import { getWPGraphQLData } from "../api/my-api";
import { useQuery } from "react-query";


const Header = () => {
    // const staticQueryData = useStaticQuery(graphql`
    //     query {
    //         components: wpPage(id: {eq: "cG9zdDoxMzk="}) {
    //                 headerCustomFields {
    //                 siteName
    //                 siteLogo {
    //                     sourceUrl
    //                 }
    //             }
    //         }
    //     }
    // `)

    const WP_GQL_HEADER_QUERY = `{
        components: page(id: "cG9zdDoxMzk=") {
            headerCustomFields {
                siteName
                siteLogo {
                    sourceUrl
                }
            }
        }
    }`
    const { data, isLoading, error } = useQuery("WPGraphQLData", async () => getWPGraphQLData(WP_GQL_HEADER_QUERY))
    const [sticky, setSticky] = useState("")
    const [siteName, setSiteName] = useState<any>([])
    const [siteLogo, setSiteLogo] = useState('')

    // on render, set listener
    useEffect(() => {
        window.addEventListener("scroll", isSticky)
        return () => {
            window.removeEventListener("scroll", isSticky)
        };
    }, []);

    useEffect(() => {
        if (data) {
            setSiteLogo(data.components.headerCustomFields.siteLogo.sourceUrl)
            setSiteName(data.components.headerCustomFields.siteName.split(' '))
        }
    }, [data]);

    const isSticky = () => {
        /* Method that will fix header after a specific scrollable */
        const scrollTop = window.scrollY
        const stickyClass = scrollTop > 0 ? "is-sticky" : ""
        setSticky(stickyClass)
        // console.log(stickyClass);
    };

    const headerLogoContainerMotion: Variants = {
        hidden: {
            opacity: 0,
        },
        show: {
            opacity: 1,
            transition: {
                duration: .25,
                staggerChildren: .15
            }
        }
    }

    const headerLogoMotion: Variants = {
        hidden: {
            opacity: 0,
            scale: .35
        },
        show: {
            opacity: 1,
            scale: 1,
            transition: {
                type: "spring",
                bounce: .5,
                duration: .65
            }
        }
    }

    const headerMotion: Variants = {
        hidden: {
            opacity: 0,
            x: -50
        },
        show: {
            opacity: 1,
            x: 0,
            transition: {
                duration: .25
            }
        }
    }

    const StaticImageMotionComponent = motion(StaticImage)


    return (
        <header className={`${header} ${sticky && "sticky-header"}`}>
            {
                data &&
                <>
                    <motion.div className={headerLeft}
                        variants={headerLogoContainerMotion}
                        initial="hidden"
                        animate="show"
                    >
                        <Link to="/">

                            <>
                                <motion.img
                                    variants={headerLogoMotion}
                                    src={siteLogo}
                                    className="Index-logo"
                                    width={46}
                                    height={46}
                                    alt={siteName + ' Logo'}
                                />

                                <motion.div variants={headerMotion}>
                                    <span>{siteName[0]}</span> <span>{siteName[1]} {siteName[2]}</span>
                                </motion.div>
                            </>

                        </Link>
                    </motion.div>

                    <div className={headerRight}>
                        {
                            // appContext!.navAboutUsLink &&
                            <>
                                <Link
                                    to="/about"
                                    activeClassName={navActive}
                                >
                                    {/* <div>About Us</div> */}
                                </Link>
                                {/* <Link to="/blog">Blog</Link> */}
                            </>
                        }
                    </div>
                </>
            }
        </header >

    );
}

export default Header;