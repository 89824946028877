const endpoint = "https://taylormanualtherapy.com/wordpress/graphql";

export const getWPGraphQLData = (query: string) => {
    return (
        fetch(endpoint, {
            method: "POST",
            headers: {"Content-Type": "application/json"},
            body: JSON.stringify({query})
        })
        .then((response) => {
            if (response.status >= 400) {
                throw new Error("Error fetching data");
            } else {
                return response.json();
            }
        })
        .then((data) => data.data)
    )
}

// function AppContent() {  
//     const WP_GQL_QUERY = `{
//           home: page(id: "cG9zdDo2") {
//             homePageCustomFields {
//               heroText
//               heroDescription
//               backgroundImage {
//                 mediaItemUrl
//               }
//             }
//           },
//           components: page(id: "cG9zdDoxMzk=") {
//             sessionPricesComponentCustomFields {
//               sessionsNote
//               bookSessionButtonLabel
//               bookingUrl
//             }
//             operationHoursCustomFields {
//                 phoneNumber
//               }
//             modalitiesCustomFields {
//               modalitiesSectionTitle
//             }
//             therapistsComponentCustomFields {
//                 therapistsSectionTitle
//               }
//               affiliatesComponentCustomFields {
//                 affiliatesSectionTitle
//               }
//             footerCustomFields {
//               address
//               copyright
//               email
//             }
//             headerCustomFields {
//               siteName
//               siteLogo {
//                 mediaItemUrl
//               }
//             }
//           },
//           sessionPrices {
//             nodes {
//               sessionPrice {
//                 fieldGroupName
//                 sessionLength
//                 sessionLengthLabel
//                 sessionPrice
//                 sessionPriceCurrencySymbol
//               }
//             }
//           },
//           modalities {
//             nodes {
//               title
//               featuredImage {
//                 node {
//                   mediaItemUrl
//                   mediaDetails {
//                     height
//                     width
//                   }
//                 }
//               }
//             }
//           },
//           therapists {
//             nodes {
//               title
//               content
//               therapistsCustomFields {
//                 degree
//                 fieldGroupName
//                 license
//                 licenseNumber
//                 other
//                 photo {
//                   mediaItemUrl
//                 }
//               }
//             }
//           },
//           services {
//             nodes {
//               serviceCustomFields {
//                 serviceDescription
//               }
//               featuredImage {
//                 node {
//                   id
//                   mediaItemUrl
//                 }
//               }
//               title
//             }
//           },
//           affiliates {
//             nodes {
//               id
//               title
//               featuredImage {
//                 node {
//                   mediaItemUrl
//                   mediaDetails {
//                     height
//                     width
//                   }
//                 }
//               }
//               affiliateCustomFields {
//                     affiliateUrl
//                   }
//             }
//           }
//       }
//     `;
