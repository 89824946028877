import * as React from "react"
import { graphql, useStaticQuery } from 'gatsby'
import AppContextProvider from "../contexts/GlobalContext";
import Header from "./header"
import Footer from "./footer";
import './globals.scss'
import {
    app,
    appBody
} from './layout.module.scss'
import {
    useQuery,
    useQueryClient,
    QueryClient,
    QueryClientProvider,
} from 'react-query'
const queryClient = new QueryClient()

const Layout = ({ pageTitle, children }: any) => {
    const data = useStaticQuery(graphql`
        query MyQuery {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)

    return (
        <QueryClientProvider client={queryClient}>
            <AppContextProvider>
                <div className={app}>
                    {/* <header className={siteTitle}>{data.site.siteMetadata.title}</header> */}
                    <Header />
                    <main className={appBody}>
                        {/* <h1 className={heading}>{pageTitle}</h1> */}
                        {children}
                    </main>
                    <Footer />
                </div>
            </AppContextProvider>
        </QueryClientProvider>
    )
}

export default Layout
