import React, { createContext, useEffect, useState } from 'react'

interface AppContextInterface {
    navMessageButton: boolean,
    navAboutUsLink: boolean,
    isMobile: boolean
}

export const AppCtx = createContext<AppContextInterface | null>(null);

const appContext: AppContextInterface = {
    navMessageButton: true,
    navAboutUsLink: true,
    isMobile: false
};

const AppContextProvider = (props: any) => {
    const [statusNavMessageButton, setStatusNavMessageButton] = useState(false);
    const Url = ""

    const [isMobile, setIsMobile] = useState<any>();

    useEffect(() => {
        if (typeof window !== 'undefined') {
            window
                .matchMedia("(max-width: 768px)")
                .addEventListener('change', e => setIsMobile(e.matches));
        }
    }, []);

    useEffect(() => {
        appContext.isMobile = isMobile;
    }, [isMobile]);

    return (
        <AppCtx.Provider value={appContext}>
            {props.children}
        </AppCtx.Provider>
    )
}
export default AppContextProvider