import React from "react"
import Layout from "./src/components/layout"
import { ThemeProvider, CssBaseline } from "@mui/material";
import customTheme from "./src/themeOveride";
import TawkMessengerReact from '@tawk.to/tawk-messenger-react';

export const wrapRootElement = ({ element }) => {
    return (
        <ThemeProvider theme={customTheme}>
            <CssBaseline />
            {element}
        </ThemeProvider>
    )
}

// Wraps every page in a component
export const wrapPageElement = ({ element, props }) => {
    return <Layout {...props}>
        {element}

        <TawkMessengerReact
                propertyId="63c23d6fc2f1ac1e202d6049"
                widgetId="1gmnbvv0f"/>
    </Layout>
}