import { useStaticQuery, graphql } from "gatsby"
import * as React from "react"
import { email, copyright } from './footer.module.scss'

export default function Footer() {
    const data = useStaticQuery(graphql`
        query {
            components: wpPage(id: {eq: "cG9zdDoxMzk="}) {
                footerCustomFields {
                    address
                    copyright
                    email
                }
            }
        }
    `)

    return (
        <div className="app-footer">
            <div className="address">{data.components.footerCustomFields.address}</div>
            <div className={email}>
                <a
                    rel=""
                    role="link"
                    href={`mailto:${data.components.footerCustomFields.email}`}
                    target="_blank"
                >
                    {data.components.footerCustomFields.email}
                </a>
            </div>
            <div className={copyright}>{data.components.footerCustomFields.copyright}</div>
        </div>
    )
}